import { pdfHeader } from "@/utilities/pdfUtils";
import { formatFeeName } from "@/utilities/settings";
import writtenNumber from "written-number";

const printPDF = (record, settings, nomClient, studentName) => {
  console.log(record);
  const tva = record.tva;
  const title = "Reçu de paiement";

  const doc = pdfHeader(settings, nomClient, title);
  var arr = [];
  const array = [];
  let firstTransition = record.transactions[0];
  let onTransReduction = firstTransition.onTransReduction
    ? firstTransition.onTransReduction / 100
    : 0;
  let baseReductionExist = record.transactions.some((transaction) => {
    const repReduction = transaction.payment.repReduction;
    if (
      repReduction &&
      typeof repReduction === "object" &&
      Object.keys(repReduction).length > 0
    ) {
      for (const month in transaction.info.months) {
        if (Number(repReduction[month])) {
          return true;
        }
      }
      if (transaction.onInscription && repReduction.inscription) return true;
    }
    return false;
  });
  let rowData = [0, 0, 0, 0];

  const studentFees = new Map();

  for (const t of record.transactions) {
    record = t;
    let repReduction = record.payment.repReduction;

    if (record.fees) {
      Object.keys(record.fees).forEach((k) => {
        const feeData = {
          serial: record.serial,
          feeName: formatFeeName(k),
          typeReglement: record.typeReglement,
          reduction: repReduction[k]
            ? Number(repReduction[k]).toFixed(2) + "%"
            : "0%",
          amount: (record.typeFR == "F"
            ? record.fees[k] - record.fees[k] * record.tva
            : record.fees[k]
          ).toFixed(2),
        };

        if (!studentFees.has(studentName)) {
          studentFees.set(studentName, []);
        }
        studentFees.get(studentName).push(feeData);
      });
    }

    rowData[0] += record.montantHT;
    rowData[1] += record.montantTTC - record.montantHT - record.fiscal;
    rowData[2] += record.fiscal;
    rowData[3] += record.montantTTC;
  }

  studentFees.forEach((fees, student) => {
    fees.forEach((fee, index) => {
      arr = [];
      arr.push(fee.serial);

      if (index === 0) {
        arr.push({
          content: student,
          rowSpan: fees.length,
          styles: { valign: "middle" },
        });
      }

      arr.push(fee.feeName);
      arr.push(fee.typeReglement);

      if (baseReductionExist) {
        arr.push(fee.reduction);
      }

      arr.push(fee.amount);
      array.push(arr);
    });
  });

  if (onTransReduction) {
    if (record.typeFR == "R") {
      arr = [];
      arr.push({
        colSpan: baseReductionExist ? 5 : 4,
        content: "Total sans réduction",
        styles: { valign: "middle", halign: "center", fontStyle: "bold" },
      });
      arr.push({
        content: (rowData[3] / (1 - onTransReduction)).toFixed(2),
        styles: { valign: "middle", halign: "center", fontStyle: "bold" },
      });
      array.push(arr);
    }
    arr = [];
    arr.push({
      colSpan: baseReductionExist ? 5 : 4,
      content: "Réduction",
      styles: { valign: "middle", halign: "center", fontStyle: "bold" },
    });
    arr.push({
      content: (onTransReduction * 100).toFixed(2) + "%",
      styles: { valign: "middle", halign: "center", fontStyle: "bold" },
    });
    array.push(arr);
  }

  let rowDataText = ["Total HTVA", "TVA", "Timbre", "Total TTC"];
  for (let i = 0; i < 4; i++) {
    if (record.typeFR == "R") {
      i = 3;
      rowDataText[i] = "Total";
    }
    arr = [];
    arr.push({
      colSpan: baseReductionExist ? 5 : 4,
      content: rowDataText[i],
      styles: { valign: "middle", halign: "center", fontStyle: "bold" },
    });
    arr.push({
      content: parseFloat(rowData[i]).toFixed(2),
      styles: { valign: "middle", halign: "center", fontStyle: "bold" },
    });
    array.push(arr);
  }

  const pageHeight = doc.internal.pageSize.getHeight();
  const pageWidth = doc.internal.pageSize.getWidth();
  const halfHeight = pageHeight / 2;
  const estimatedSecondTableHeight = (array.length + 2) * 10 + 40;

  doc.autoTable({
    styles: {
      lineWidth: 0.2,
      valign: "middle",
      halign: "center",
      fontStyle: "bold",
      font: "Amiri",
    },
    startY: 38,
    head: baseReductionExist
      ? [
          [
            "Num",
            "Enfant",
            "Frais",
            "Désignation",
            "Réduction de base",
            "Montant",
          ],
        ]
      : [["Num", "Enfant", "Frais", "Désignation", "Montant"]],
    body: array,
    foot: [
      [
        {
          colSpan: baseReductionExist ? 6 : 5,
          content:
            "Le montant total est " +
            writtenNumber(rowData[3], { lang: "fr" }) +
            " DT",
          styles: { halign: "center" },
        },
      ],
    ],
  });

  const firstTableEndY = doc.lastAutoTable.finalY;
  const remainingSpace = pageHeight - firstTableEndY;

  if (remainingSpace > estimatedSecondTableHeight + 60) {
    doc.setDrawColor(128, 128, 128);
    doc.setLineWidth(0.5);
    doc.line(10, halfHeight, pageWidth - 10, halfHeight);

    let arrayOfImages = settings.image;
    let imgData = new Image();
    for (let i = 0; i < arrayOfImages.length; i++) {
      if (settings.activeBuilding === arrayOfImages[i].db) {
        imgData.src = arrayOfImages[i].logo;
        break;
      }
    }
    if (imgData.src) {
      doc.addImage(imgData, "JPEG", 15, halfHeight + 5, 20, 20);
    }
    doc.setFont("Amiri");
    doc.setFontSize(15);
    const titleWidth = doc.getTextWidth(title);
    const titleX = (pageWidth - titleWidth) / 2;
    doc.text(titleX, halfHeight + 22, title);

    if (nomClient) {
      doc.text(15, halfHeight + 35, `Nom et Prénom: ${nomClient}`);
    }

    doc.setFontSize(8);
    const date = new Date();
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    doc.text(170, halfHeight + 15, date.toLocaleDateString("fr-FR", options));

    doc.autoTable({
      styles: {
        lineWidth: 0.2,
        valign: "middle",
        halign: "center",
        fontStyle: "bold",
        font: "Amiri",
      },
      startY: halfHeight + 38,
      head: baseReductionExist
        ? [
            [
              "Num",
              "Enfant",
              "Frais",
              "Désignation",
              "Réduction de base",
              "Montant",
            ],
          ]
        : [["Num", "Enfant", "Frais", "Désignation", "Montant"]],
      body: array,
      foot: [
        [
          {
            colSpan: baseReductionExist ? 6 : 5,
            content:
              "Le montant total est " +
              writtenNumber(rowData[3], { lang: "fr" }) +
              " DT",
            styles: { halign: "center" },
          },
        ],
      ],
    });
  } else {
    doc.addPage();

    let arrayOfImages = settings.image;
    let imgData = new Image();
    for (let i = 0; i < arrayOfImages.length; i++) {
      if (settings.activeBuilding === arrayOfImages[i].db) {
        imgData.src = arrayOfImages[i].logo;
        break;
      }
    }
    if (imgData.src) {
      doc.addImage(imgData, "JPEG", 15, 5, 20, 20);
    }
    doc.setFont("Amiri");
    doc.setFontSize(15);
    const titleWidth = doc.getTextWidth(title);
    const titleX = (pageWidth - titleWidth) / 2;
    doc.text(titleX, 22, title);

    if (nomClient) {
      doc.text(15, 35, `Nom et Prénom: ${nomClient}`);
    }

    doc.setFontSize(8);
    const date = new Date();
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    doc.text(170, 15, date.toLocaleDateString("fr-FR", options));

    doc.autoTable({
      styles: {
        lineWidth: 0.2,
        valign: "middle",
        halign: "center",
        fontStyle: "bold",
        font: "Amiri",
      },
      startY: 38,
      head: baseReductionExist
        ? [
            [
              "Num",
              "Enfant",
              "Frais",
              "Désignation",
              "Réduction de base",
              "Montant",
            ],
          ]
        : [["Num", "Enfant", "Frais", "Désignation", "Montant"]],
      body: array,
      foot: [
        [
          {
            colSpan: baseReductionExist ? 6 : 5,
            content:
              "Le montant total est " +
              writtenNumber(rowData[3], { lang: "fr" }) +
              " DT",
            styles: { halign: "center" },
          },
        ],
      ],
    });
  }

  doc.save(title + ".pdf");
};

export { printPDF };
